<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BAvatar, BForm, BFormGroup, BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    VueGoodTable,
    vSelect,
    BAvatar,
    BForm, BFormGroup, BFormDatepicker
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Họ và tên',
            field: 'full_name',
            sortable: false,
          },
          {
            label: 'Lớp',
            field: 'ten_lop',
            sortable: false,
          },
          {
            label: 'Tên sách',
            field: 'name',
            sortable: false,
          },
          {
            label: 'Số đăng ký',
            field: 'dkcb_code',
            sortable: false,
          },
          {
            label: 'Ngày mượn',
            field: 'input_start_date',
            sortable: false,
          },
          {
            label: 'Ngày nhận trả',
            field: 'input_end_date',
            sortable: false,
          },
          {
            label: 'Trạng thái',
            field: 'status_borrow',
            sortable: false,
          },
          {
            label: '',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        input_start_date: "",
        input_end_date: "",
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      dataSendForm: {},
      total: 0,
    }
  },
  async created() {
    // init data FROM API
    this.serverParams.input_end_date = new Date()
    const before7Days = new Date()
    before7Days.setDate(before7Days.getDate() - 30)
    this.serverParams.input_start_date = before7Days
    await this.loadItems()
  },
  computed: {
  },
  watch: {
    // listening change data init
  },
  methods: {
    ...mapActions({
      reportNumberBorrowingsAndRetuns: 'digitalLibrary/reportNumberBorrowingsAndRetuns',
    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true
      const body = {
        input_start_date: this.convertDateToString(this.serverParams.input_start_date),
        input_end_date: this.convertDateToString(this.serverParams.input_end_date),
        page:this.serverParams.page,
        item_per_page: this.serverParams.perPage
      }
      const data = await this.reportNumberBorrowingsAndRetuns(body)
      const dataResponse = JSON.parse(data)
      this.table.rows = dataResponse.rows
      this.total = dataResponse.total_rows ? dataResponse.total_rows : dataResponse.total_record
      this.isLoading = false
    },

    convertDateToString(input) {
      if (typeof input == "string") {
        const dateParts = input.split("-")
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
      }else {
        const day = input.getDate()
        const month = (input.getMonth() + 1).toString().padStart(2, '0')
        const year = input.getFullYear()
        return `${day}/${month}/${year}`
      }

    },

    getNameGender(id) {
      let gt = ''
      if (id == '2') {
        gt = 'Nữ'
      }else if(id == '1'){
        gt = 'Nam'
      } else {
        gt = 'Giới tính khác'
      }
      return gt
    },

    getNameStatusBorrow(id) {
      let result = ""
      switch (id) {
        case 1 :
          result = "Chưa trả"
          break
        case 2 :
          result = "Đã trả đúng hạn"
          break
        case 3 :
          result = "Đã trả quá hạn"
          break
        case 4 :
          result = "Bạn đọc làm mất chưa đèn bù"
          break
        default :
          result = "Chưa xác định"
          break
      }
      return result
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
